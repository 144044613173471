import React from "react";
import {
  Hero,
} from "../../components/component";
import Meta from "../../components/Meta";
import Newsletter from "../../components/nwesletter/newsletter";

const Home_1 = () => {
  return (
    <main>
      <Meta title="My French" />
      <Hero />
        <Newsletter />

    </main>
  );
};

export default Home_1;
